import * as React from "react";
import styled from "styled-components";
import { Seo } from "../components/atoms";
import { LayoutComponent } from "../components/organisms";

const Body = styled.div``

const PrivacyPage = () => {
  return (
    <LayoutComponent hasTitle={true}>
      <h1>Privacy Policy</h1>
      <Body>
        <p>このサイトのプライバシーポリシーと運営の指針について記載しています。</p>
        <h2>Cookieについて</h2>
        <p>
          このサイトでは、
          <ul>
            <li>アクセス解析</li>
            <li>広告の表示・表示回数やクリック数の測定</li>
          </ul>
          を目的として、Cookieを使用しています。
        </p>
        <h2>アクセス解析について</h2>
        <p>
          このサイトでは、「Google Analytics」を使用してアクセス解析を行っています。 Google Analyticsはトラフィックデータの収集のためにCookieを使用しています。 このトラフィックデータは匿名で収集されており、個人を特定するものではありません。
        </p>
        <p>
          Google Analyticsがデータを収集、処理する仕組みについては、<a href="https://www.google.com/intl/ja/policies/privacy/partners/" target="_blank" rel="noreferrer">ユーザーがGoogleパートナーのサイトやアプリを使用する際のGoogleによるデータ使用</a>をご確認ください。
        </p>
        <p>
          Cookieを無効にすることで収集を拒否することが出来ます。 Cookieを無効にする場合は、お使いのブラウザの設定をご確認ください。
        </p>
        <h2>広告について</h2>
        <p>
          このサイトでは、「Google Adsense」を使用して、第三者配信による広告を掲載しています。
        </p>
        <p>
          Googleなどの第三者配信事業者がCookieを使用して、ユーザーがそのウェブサイトや他のウェブサイトに過去にアクセスした際の情報に基づいて広告を配信します。
        </p>
        <p>
          Googleが広告Cookieを使用することにより、ユーザーがそのサイトや他のサイトにアクセスした際の情報に基づいて、Googleやそのパートナーが適切な広告をユーザーに表示できます。
        </p>
        <p>
          ユーザーは、<a href="https://www.google.com/settings/ads" target="_blank" rel="noreferrer">広告設定</a>でパーソナライズ広告を無効にすることができます。<br />
          または、<a href="https://youradchoices.com/" target="_blank" rel="noreferrer">https://youradchoices.com/</a>にアクセスすることで、パーソナライズ広告に使われる第三者配信事業者のCookieを無効にすることができます。
        </p>
        <h2>免責事項</h2>
        <p>
          <p>このサイトの内容は、shimar個人の主張、見解、考えであり、shimarの所属する団体、組織とは関係ありません。</p>
          <p>このサイトの全てのコンテンツ・情報について、可能な限り正確な情報を掲載するよう努めていますが、 必ずしも正確性・信頼性等を保証できるわけではありません。</p>
          <p>このサイトの内容によって生じた損害などの一切の責任は負いかねます。</p>
        </p>
      </Body>
    </LayoutComponent>
  )
}

export default PrivacyPage
export const Head = () => (
  <Seo title="Privacy Policy"
    pathname="/privacy" />
)
